/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import * as localforage from 'localforage';
import { DateTime } from 'luxon';
import { Observable, Subject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { OfflineElementDTO } from '../DTO/OfflineElementDTO';
import { ImageDTO } from './../DTO/ImageDTO';

export enum OfflineStorageElement {
  ProjectsAllUuids, // Only Pull
  Projects, // Sync
  Registers, // Sync
  Units, // Sync
  Trucks, // ??
  Images, // Sync
  //Logs,
  Templates, // Sync
  RecentAccess, // Only device
  OfflineMarkedProjects, // Only device
  PinnedProjects, // Sync (Pinned/Favorite)
  ProjectDynamicFields
 };

@Injectable({
  providedIn: 'root'
})
export class OfflineStorageService {

  public events = new Subject<[string, string]>();

  constructor(

  ) { }

  public clear(){
    return localforage.clear();
  }

  public setLastSyncDateTime(obj: DateTime): Promise<null>{
    return this.store_set('LastSync', obj.toISO());
  }

  public async getLastSyncDateTime(): Promise<DateTime>{
    const value = await this.store_get('LastSync');
    return value? DateTime.fromISO(value.toString()) : value;
  }

  public setUser(obj: any): Promise<null>{
    return this.store_set('User', obj);
  }

  public async getUser(): Promise<any>{
    const key = 'User';
    const value = await this.store_get(key);
    this.events.next(['set', key]);
    return value;
  }

  public getUserObs(): Observable<any>{
    return new Observable<any>(subscriber => {
      const key = 'User';

      const emitFn = () => this.store_get(key)
      .then((res) => {
        subscriber.next(res);
      });

      emitFn();

      this.events.subscribe((res) => {
        if(res[0] === 'set' && res[1] === key){
          emitFn();
        }
      });

    });;
  }

  public async set<T>(el: OfflineStorageElement, obj: T, emitChange = true): Promise<T>{
    const key = OfflineStorageElement[el];
    return this.store_set(key, obj, emitChange);
  }

  public get<T>(el: OfflineStorageElement, includeDeleted = false): Observable<T>{
    const key = OfflineStorageElement[el];

    const observable = new Observable<T>(subscriber => {
      const emitFn = () => this.store_get(key).then((res) => {
        let out = res;
        if(!includeDeleted && Array.isArray(res)){
          out = res.filter(x => !x.deleted);
        }
        subscriber.next(out);
      });

      emitFn();

      this.events.subscribe((res) => {
        if(res[0] === 'set' && res[1] === key){
          emitFn();
        }
      });

    });

    return observable;
  }

  public getByUuid<T extends OfflineElementDTO>(el: OfflineStorageElement, uuid: string, nullIfNotExists = false): Observable<T>{
    const key = OfflineStorageElement[el];

    const observable = this.get<T[]>(el).pipe(map(res => {
      const found = res?.find(x => x.uuid === uuid);
      if(found){
        return found;
      } else {
        if(nullIfNotExists){
          return null;
        } else {
          throw new Error(key + ' NOT FOUND uuid: ' + uuid);
        }
      }
    }));

    return observable;
  }

  public getById<T>(el: OfflineStorageElement, id: number): Observable<T>{
    const key = OfflineStorageElement[el];

    const observable = this.get<any[]>(el).pipe(map(res => {
      const found = res?.find(x => x.id === id);
      if(found){
        return found;
      } else {
        throw new Error(key + ' NOT FOUND id: ' + id);
      }
    }));

    return observable;
  }

  public getDirty<T extends OfflineElementDTO>(el: OfflineStorageElement, includeDeleted = false): Observable<T[]>{
    return this.get<T[]>(el, includeDeleted).pipe(first(), map(arr =>
      (arr || []).filter( r => r.dirty )
    ));
  }
  public getDeleted<T extends OfflineElementDTO>(el: OfflineStorageElement): Observable<T[]>{
    return this.get<T[]>(el , true).pipe(first(), map(arr =>
      (arr || []).filter( r => r.dirty && r.deleted)
    ));
  }

  public syncWithDirty<T extends OfflineElementDTO>(elType: OfflineStorageElement, fromServer: T[], removeIfNotInServer = false){
    return new Promise((resolve, reject) => {
      this.get<T[]>(elType)
      .pipe(first())
      .subscribe(fromLocal => {

      fromLocal = fromLocal || [];

      const uuidsFromServer = fromServer.map(x => x.uuid);

      const result = fromServer
        .concat(fromLocal.filter(x => !uuidsFromServer.includes(x.uuid) && (x.dirty || !removeIfNotInServer) ));

      this.set(elType, result).then(resolve).catch(reject);
     });
    });
  }

  public async push<T extends OfflineElementDTO>(el: OfflineStorageElement, obj: T, setDirty = true, emitChange = true): Promise<T>{
    if(setDirty) { obj.dirty = true; }

    const key = OfflineStorageElement[el];

    const list = await this.store_get(key) ?? [];
    list.push(obj);

    await this.set(el, list, emitChange);
    return list;
  }

  public async pushOrUpdateByUuid<T extends OfflineElementDTO>(el: OfflineStorageElement, obj: T, setDirty = true): Promise<T>{
    if(setDirty) { obj.dirty = true; }

    const key = OfflineStorageElement[el];

    let list = await this.store_get(key) ?? [];

    if(list.find(x => x.uuid === obj.uuid)){
      list = list.filter(x => x.uuid !== obj.uuid);
    }

    list.push(obj);

    await this.set(el, list);
    return list;
  }

  public async update<T extends OfflineElementDTO>(el: OfflineStorageElement, obj: T, setDirty = true, emitChange = true): Promise<T>{
    if (setDirty) { obj.dirty = true; }

    const key = OfflineStorageElement[el];

    const list = await this.store_get(key) ?? [];

    const index = list.findIndex( x => x.uuid === obj.uuid);
    if(index === -1){
      return Promise.reject(key + ' NOT FOUND : ' + obj.uuid);
    }

    list[index] = obj;

    await this.set(el, list, emitChange);
    return Promise.resolve(obj);
  }

  public async removeByUuid<T extends OfflineElementDTO>(el: OfflineStorageElement, obj: T, emitChange = true): Promise<T>{
    const key = OfflineStorageElement[el];

    let list = await this.store_get(key) ?? [];

    list = list.filter(x => x.uuid !== obj.uuid);

    await this.set(el, list, emitChange);
    return list;
  }

  public async removeImages(paths: string[], emitChange = true): Promise<void>{
    const el = OfflineStorageElement.Images;
    const key = OfflineStorageElement[el];

    const list = (await this.store_get(key) ?? []) as ImageDTO[];

    const elementsToRemove = list.filter(x => paths.includes(x.path));

    if (!elementsToRemove.length) { return; }

    for (const elementToRemove of elementsToRemove) {
      if(elementToRemove.dirty){
        const index = list.indexOf(elementToRemove);
        if (index > -1) {
          list.splice(index, 1);
        }
      } else {
        elementToRemove.deleted = true;
        elementToRemove.dirty = true;
      }
    }

    await this.set(el, list, emitChange);
  }

  public emitChanges(el: OfflineStorageElement){
    const key = OfflineStorageElement[el];
    this.events.next(['set', key]);
  }

  //#region PRIVATE
  private async store_get(key: string): Promise<any>{
    return localforage.getItem(key);
  }

  private async store_set(key: string, obj: any, emitChange = true): Promise<any>{
    return localforage.setItem(key, obj)
      .then(() => {
        if (emitChange){ this.events.next(['set', key]); }
      });
  }
  //#endregion

}
